<h3>Cart</h3>

<p>
  <a routerLink="/shipping">Shipping Prices</a>
</p>

<div class="cart-item" *ngFor="let item of items">
  <span>{{ item.name }}</span>
  <span>{{ item.price | currency }}</span>
</div>

<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
  <div>
    <label for="name"> Name </label>
    <input id="name" type="text" class="form-control" formControlName="name" required minlength="4" />
  </div>

  <div>
    <label for="address"> Address </label>
    <input id="address" type="text" formControlName="address" />
  </div>

  <button class="button" type="submit">Purchase</button>
</form>
